import React, { FC } from 'react'

import { Grid, Link, Stack, Theme, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Text from '~/components/base/Text'

import { useGetAllArticlesQuery } from '~/services/Query/articles/articles.query'
import ROUTES from '~/utils/constants/routes'

import ArrowRightIcon from '~/assets/icons/home/arrow-right.svg'

import SectionContainer from '../SectionContainer'

import BlogPost from './BlogPost'
import msg from './BlogSection.messages'

const BlogSection: FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const articleLimit = 4

  const { data } = useGetAllArticlesQuery({
    params: { limit: articleLimit },
    options: {
      refetchOnMount: false
    }
  })

  const { articles } = data || {}

  return (
    <SectionContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Text
          size={{ xs: 'h3', md: 'h2' }}
          component="h2"
          color="textPrimary"
          fontWeight={600}
        >
          <FormattedMessage {...msg.title} />
        </Text>

        {!isMobile && (
          <Link
            href={ROUTES.media}
            fontSize={15}
            lineHeight="18px"
            fontWeight={600}
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              <FormattedMessage {...msg.blogLink} /> <ArrowRightIcon />
            </Stack>
          </Link>
        )}
      </Stack>

      <Grid container spacing={2.5} mb={{ xs: 3.5, md: 0 }}>
        {articles?.map((post) => {
          return (
            <Grid key={post.title} item xs={12} sm={6}>
              <BlogPost
                date={post.published_at}
                image={post.main_image}
                title={post.title}
                href={post.slug}
              />
            </Grid>
          )
        })}
      </Grid>

      {isMobile && (
        <Link
          href={ROUTES.media}
          fontSize={15}
          lineHeight="18px"
          fontWeight={600}
        >
          <Stack direction="row" alignItems="center" columnGap={1}>
            <FormattedMessage {...msg.blogLink} /> <ArrowRightIcon />
          </Stack>
        </Link>
      )}
    </SectionContainer>
  )
}

export default BlogSection
