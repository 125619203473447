import { defineMessages } from 'react-intl'

const msg = defineMessages({
  title: {
    defaultMessage: "What's Trending In Our Blog"
  },
  blogLink: {
    defaultMessage: 'View all blog posts'
  }
})

export default msg
