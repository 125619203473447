import { useQuery, UseQueryOptions } from 'react-query'

import Api from '~/services/Api'
import Deserializer from '~/services/Deserializer'

export const blogConfig = {
  allArticles: {
    key: 'blog/allArticles',
    request: async ({ queryKey }) => {
      const [_key, params] = queryKey

      const resp = await Api.getAllArticles(params)
      const articles = await Deserializer.deserialize(resp.articles)

      return { articles, pagy: resp.pagy }
    }
  },
  getArticle: {
    key: 'blog/getArticle',
    request: async ({ queryKey }) => {
      const [_key, params] = queryKey

      const resp = await Api.getArticle(params)
      const payload = await Deserializer.deserialize(resp)
      const meta = await Deserializer.deserialize(resp?.meta?.read_more)

      const data: ArticleModel = {
        ...payload,
        meta: { read_more: meta }
      }

      return data
    }
  }
}

interface UseGetArticlesType {
  params?: ArticleDTO
  options?: UseQueryOptions<PaginationArticleModel>
}

export const useGetAllArticlesQuery = (props: UseGetArticlesType) => {
  const { params = {}, options } = props || {}
  const { allArticles: config } = blogConfig

  const state = useQuery<PaginationArticleModel>(
    [config.key, params],
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetArticleQueryProps {
  slug: string
  options?: UseQueryOptions<ArticleModel>
}

export const useGetArticleQuery = ({
  slug,
  options
}: UseGetArticleQueryProps) => {
  const { getArticle: config } = blogConfig

  const state = useQuery<ArticleModel>([config.key, slug], config.request, {
    ...options
  })

  return state
}
