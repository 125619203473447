import Image from 'next/image'
import React, { FC } from 'react'

import { Box, Stack } from '@mui/material'

import Link from '~/components/base/Link'
import Text from '~/components/base/Text'

import IntlService from '~/services/IntlService'

interface BlogPostProps {
  image: string
  date: string
  title?: string
  href: string
}

const BlogPost: FC<BlogPostProps> = ({ date, image, title, href }) => {
  return (
    <Stack spacing={2.5} direction="row">
      <Box sx={{ '& img': { borderRadius: '8px' } }}>
        <Image
          src={image}
          layout="fixed"
          objectFit="cover"
          width={100}
          height={100}
          alt={title}
        />
      </Box>

      <Stack>
        <Text
          size={{ xs: 'subtitle1' }}
          color="text.headline"
          mb={0.5}
          sx={{ lineHeight: '24px' }}
        >
          {IntlService.formatDate(date)}
        </Text>

        <Text
          size={{ xs: 'h5', md: 'h4' }}
          fontWeight={700}
          component="h3"
          color="textPrimary"
          maxWidth={350}
        >
          <Link href={`media/${href}`}>{title}</Link>
        </Text>
      </Stack>
    </Stack>
  )
}

export default BlogPost
