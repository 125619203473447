/**
 * Intl service
 */
class IntlService {
  /**
   * Number formatter
   *
   * @param value formatted value
   * @param options Intl.NumberFormatOptions
   */
  static numberFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options
    })
    return formatter.format(Number(value))
  }

  /**
   * Currency formatter
   *
   * @param value formatter value
   * @param options Intl.NumberFormatOptions
   */
  static currencyFormat(
    value: string | number,
    options?: Intl.NumberFormatOptions
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options
    })

    return !Number.isNaN(+value) ? formatter.format(Number(value)) : ''
  }

  /**
   * Date formatter
   *
   * @param date Date value
   * @param options Intl.DateTimeFormatOptions
   */

  static formatDate(
    date: string,
    options?: Intl.DateTimeFormatOptions,
    local = 'en-au'
  ): string {
    if (!date) return ''

    const formatter = new Date(String(date))
      .toLocaleString(local, {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        ...options
      })
      .split('/')
      .join('.')

    return formatter
  }
}

export default IntlService
